import React, { useRef, useEffect, useState } from "react";
import Navigation from "./Navigation";
import Banner from "./Banner";
import UserDo from "./UserDo";
import OneCap from "./OneCap";
import Gamified from "./Gamified";
import Jackport from "./Jackport";
import Save from "./Save";
import PriceLinked from "./PriceLinked";
import MoreFun from "./MoreFun";
import StartSaving from "./StartSaving";
import Footer from "./Footer";
import {
  useJackpotPoolInfo,
  useJackpotWinningInfo,
  usePoolInfo,
  useValidateReferralCode,
  useWinningInfo,
} from "../helpers/Hooks/Api";
import { useScrollToSection } from "../helpers/Hooks/ScrollTo";

const PopUp = ({ onClose }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 md:hidden">
      <div className="bg-brandPurp w-[90%] h-[90vh] mx-auto relative">
        <div className="h-full w-full bg-[url('../public/assets/images/polygon.png')] bg-no-repeat bg-cover text-white flex flex-col justify-between items-center py-[10px] px-[10px] font-MV">
          <div className="flex justify-end w-full">
            <img
              src="/assets/images/closeWhite.png"
              alt="close"
              className="cursor-pointer"
              onClick={onClose}
            />
          </div>
          <div className="flex flex-col items-center gap-[20px] px-[5px]">
            <img src="/assets/images/1capWhite.png" alt="onecap" />
            <p className="text-center text-[0.9rem] font-[700]">
              Savings Made Fun: <br />
              Save, Play and Win with OneCap.
            </p>
          </div>
          <div className="bg-[#462257] rounded-[20px] flex flex-col w-full px-[10px] py-[20px] font-[300] gap-[5px] text-[0.8rem]">
            <div className="flex items-center pb-[5px] border-b border-white/50 gap-[20px]">
              <img
                src="/assets/images/1capWhite.png"
                alt="onecap"
                className="w-[38px] h-[43px]"
              />
              <span className="text-[0.9rem]">Install OneCap App</span>
            </div>
            <p>
              To continue, install this app on your device to easily access it
              anytime by adding to your homescreen. No app store or download
              required.
            </p>
            <p className="font-[700]">For Android Users:</p>
            <p>1. Visit the PlayStore and Search for OneCap Africa.</p>
            <p>Or</p>
            <p>
              2. Visit onecap.africa on your Google Chrome and click on the menu
              button.
              <img
                src="assets/images/pwaMenu.png"
                alt="share"
                className="inline mx-[3px]"
              />
            </p>
            <p>3. Select Add to Home Screen.</p>
            <p className="font-[700]">For Apple Users:</p>
            <p>1. Visit onecap.africa on your Safari Browser.</p>
            <p>
              2. Tap the share button
              <img
                src="assets/images/pwaShare.png"
                alt="share"
                className="inline mx-[3px]"
              />
              at the bottom of the screen.
            </p>
            <p>3. Then select Add to Home Screen.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Landing = () => {
  const [showPopup, setShowPopup] = useState(false);

  // Show pop-up 5 seconds after the component mounts
  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  // Close the pop-up and reopen after 10 seconds
  const closePopup = () => {
    setShowPopup(false);

    // Reopen the pop-up after 10 seconds
    const reopenTimer = setTimeout(() => {
      setShowPopup(true);
    }, 10000);

    // Cleanup if component unmounts before timeout
    return () => clearTimeout(reopenTimer);
  };

  useValidateReferralCode();
  useWinningInfo();
  useJackpotWinningInfo();
  usePoolInfo();
  useJackpotPoolInfo();
  const gRef = useRef();
  const jRef = useRef();
  const fRef = useRef();

  useScrollToSection(gRef, jRef, fRef);

  return (
    <div>
      <Navigation />
      <Banner />
      <UserDo />
      <OneCap />
      <Gamified gamifiedRef={gRef} />
      <Jackport jackpotRef={jRef} />
      <Save />
      <PriceLinked />
      <MoreFun />
      <StartSaving />
      <Footer footerRef={fRef} />
      {/* Conditionally render the pop-up */}
      {showPopup && <PopUp onClose={closePopup} />}
    </div>
  );
};

export default Landing;
